<template>
  <div class="index">
    <!-- <PCNavheader /> -->
    <H5Navheader :name="'文化数据'" />
    <!-- 主体内容 -->
    <div class="main-body">
      <div class="data-card-list">
        <div class="item">
          <div class="type-image i-1"></div>
          <div class="content-wrap">
            <div class="title">文化数据已分发总量</div>
            <div class="amount" v-loading="dataLoading">
              <el-statistic group-separator="," :precision="0" :value="summaryInfo.total"></el-statistic>
              <!-- {{  summaryInfo.total }} -->
            </div>
            <div class="text">TOTAL</div>
          </div>
        </div>
        <div class="item">
          <div class="type-image i-2"></div>
          <div class="content-wrap">
            <div class="title">文化数据流转总额</div>
            <div class="amount" v-loading="dataLoading">
              <el-statistic group-separator="," :precision="2" :value="Number(summaryInfo.turnover)"></el-statistic>
              <!-- {{  summaryInfo.turnover  }} -->
            </div>
            <div class="text">A TURNOVER</div>
          </div>
        </div>
        <div class="item">
          <div class="type-image i-3"></div>
          <div class="content-wrap">
            <div class="title">文化数据总持有人数</div>
            <div class="amount" v-loading="dataLoading">
              <el-statistic group-separator="," :precision="0" :value="summaryInfo.peopleSum"></el-statistic>
              <!-- {{  summaryInfo.peopleSum }} -->
            </div>
            <div class="text">PEOPLE</div>
          </div>
        </div>
      </div>

      <div class="table-wrap-pc" style="margin-bottom: 124px">
        <div class="title">最新流转</div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column prop="blockNumber" label="区块高度" width="100">
            <!-- <template slot-scope="scope">
              <router-link to="/address">{{scope.row.blockNumber}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="transactionHash" label="交易哈希" width="350">
            <template slot-scope="scope">
              <router-link :to="`/hexDetail?keyword=${scope.row.transactionHash}`">{{scope.row.transactionHash}}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="blockHash" label="区块哈希" width="350">
            <!-- <template slot-scope="scope">
              <router-link to="/hexDetail">{{scope.row.name}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="count" label="接收"> </el-table-column>
          <el-table-column prop="createTime" label="时间"> </el-table-column>
        </el-table>
        <pagination v-show="total > 0" :total="total" :page.sync="params.pageNum" :limit.sync="params.pageSize"
          @pagination="getList" />
      </div>

      <div class="chart-wrap">
        <div class="header">
          <div class="title">近期总持有人数变化图</div>
        </div>
        <div ref="lineChartRef" style="width: 100%; height: 450px" />
      </div>

      <div class="list-wrap-h5">
        <div class="title">最新流转</div>
        <div class="card-item" v-for="item in tableData" :key="item.id">
          <div class="hex">
            <div class="text">
              <!-- {{item.blockHash}} -->
              <template>
                <router-link :to="`/hexDetail?keyword=${item.transactionHash}`">{{item.blockHash}}</router-link>
              </template>
            </div>
            <div class="title">区域哈希</div>
          </div>
          <div class="info">
            <div class="info-item">
              <div class="value">{{item.blockNumber}}</div>
              <div class="title">区块高度</div>
            </div>
            <div class="info-item">
              <div class="value">{{item.count}}</div>
              <div class="title">流转数量</div>
            </div>
            <div class="info-item">
              <div class="value">{{item.createTime}}</div>
              <div class="title">时间</div>
            </div>
          </div>
        </div>
        <div v-if="loading" class="h5-loading-wrap"><van-loading type="spinner" size="24px" color='#fff'>加载中...</van-loading></div>
      </div>
    </div>
    <H5Tab :index="0" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import H5Tab from '@/components/H5Tab'
import H5Navheader from '@/components/H5Navheader'
import { summaryData, listData, holderNum } from '@/api/index.js'

export default {
  name: 'Index',
  components: {
    H5Tab,
    H5Navheader,
  },
  data() {
    return {
      loading: true,
      params: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'create_time',
        isAsc: 'desc'
      },
      total: 0,
      tableData: [],
      lineChart: null, // 折线图
      option: {
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      },
      // 基本信息
      summaryInfo: {
        total: 0,
        turnover: 0,
        peopleSum: 0,
      },
      pages: 0,
      dataLoading: true
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollBottom);
  },
  mounted() {
    this.getList()
    this.getSummary()
    this.getHolderNum()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom) //页面离开后销毁监听事件
  },
  methods: {
    /** 渲染echart */
    renderChart() {
      this.lineChart = echarts.init(this.$refs.lineChartRef)
      this.lineChart.setOption(this.option)
      window.addEventListener('resize', () => {
        this.lineChart.resize()
      })
    },
    /** 获取汇总统计 */
    getSummary() {
      this.dataLoading = true
      summaryData().then((res) => {
        this.summaryInfo = res.data
        this.dataLoading = false
      })
    },
    /** 获取最新交换数据 */
    getList() {
      this.loading = true
      listData(this.params).then((res) => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
        this.pages = res.pages
      })
    },
    /** 获取持有人数 */
    getHolderNum() {
      holderNum({searchType:'LAST_THIRTY'}).then((res) => {
        // console.log(res)
        const min = res.data.reduce(function(prev, curr) {
          return (Number(prev.people) < Number(curr.people)) ? prev : curr;
        });

        const max = res.data.reduce(function(prev, curr) {
          return (Number(prev.people) > Number(curr.people)) ? prev : curr;
        });
        this.option = {
          ...this.option,
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: res.data.map((item) => this.$moment(item.createTime).format("MM.DD")),
          },
          yAxis: {
            type: 'value',
            min: (min.people * 0.99).toFixed(0),
            max: (max.people * 1.01).toFixed(0),
          },
          series: [
            {
              data: res.data.map((item) => item.people),
              type: 'line',
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(81, 190, 205, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'RGBA(26, 55, 153, 0.5)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'transparent', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                normal: {
                  color: '#52EAED',
                },
              },
            },
          ],
        }
        this.renderChart();
      })
    },
    /** h5 触底加载 */
    scrollBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight + 150 >= scrollHeight && !this.loading && this.params.pageNum < this.pages) {
        if (this.isMobile()) {
          // alert('移动端到底了')
          this.loading = true
          this.params.pageNum ++
          listData(this.params).then((res) => {
            this.tableData = this.tableData.concat(res.rows)
            // console.log(this.tableData)
            // this.total = res.total
            this.loading = false
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.index {
  background-image: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: 1920px 755px;
}

.main-body {
  .data-card-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 220px;
    @media screen and (max-width: 1200px) {
      align-items: center;
      justify-content: center;
      margin-bottom: 1.67rem;
    }
    .item {
      position: relative;
      display: flex;
      align-items: center;
      @media screen and (min-width: 1200px) {
        background: url('../assets/bg_total.png');
        width: 370px;
        height: 152px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .type-image {
          width: 101px;
          height: 73px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          left: 16px;
          top: 43px;
        }
        .i-1 {
          background-image: url('../assets/icon_total.png');
        }
        .i-2 {
          background-image: url('../assets/icon_aturnover.png');
        }
        .i-3 {
          background-image: url('../assets/icon_people.png');
        }
        .content-wrap {
          padding-left: 160px;
          .title {
            font-size: 14px;
          }
          .amount {
            font-size: 24px;
            color: #52eaed;
            padding: 14px 0;
          }
          .text {
            font-size: 14px;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        background-image: url('../assets/h5_bg_total.png');
        width: 7.5rem;
        height: 2.3rem;
        background-size: cover;
        background-position: center center;
        margin-bottom: 0.3rem;
        .type-image {
          width: 1.53rem;
          height: 1.11rem;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          left: 0.23rem;
          top: 0.65rem;
        }
        .i-1 {
          background-image: url('../assets/h5_icon_total.png');
        }
        .i-2 {
          background-image: url('../assets/h5_icon_aturnover.png');
        }
        .i-3 {
          background-image: url('../assets/h5_icon_people.png');
        }
        .content-wrap {
          padding-left: 2.42rem;
          .title {
            font-size: 0.21rem;
          }
          .amount {
            font-size: 0.36rem;
            color: #52eaed;
            padding: 0.22rem 0;
          }
          .text {
            font-size: 0.21rem;
          }
        }
      }
    }
  }

  .list-wrap-h5 {
    padding: 0.3rem 0;
    > .title {
      display: inline-block;
      font-size: 0.28rem;
      padding: 0.29rem 0.93rem;
      border-bottom: 1px solid #52eaed;
      font-weight: bold;
    }
    .card-item {
      padding: 0.3rem;
      margin: 0.3rem 0.24rem;
      &:nth-child(even) {
        background: #051363;
      }
      &:nth-child(odd) {
        background: #171749;
      }
      .hex {
        .text {
          word-break: break-all;
          font-size: 0.2rem;
          a {
             color: #52eaed;
          }
        }
        .title {
          font-size: 0.2rem;
          color: #ababff;
          padding: 0.14rem 0 0.38rem 0;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info-item {
          .value {
            font-size: 0.24rem;
            line-height: 0.24rem;
          }
          .title {
            font-size: 0.2rem;
            color: #ababff;
            padding-top: 0.1rem;
          }
        }
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.table-wrap-pc {
  // margin-bottom: 124px;
  > .title {
    display: inline-block;
    font-size: 18px;
    padding: 24px 113px;
    border-bottom: 1px solid #52eaed;
    font-weight: bold;
  }
  .el-table {
    tr:nth-child(odd) {
      background-color: #050E55;
      color: #fff;
    }
    tr:nth-child(even) {
      background-color: #051363;
      color: #fff;
    }
    tbody {
      tr:hover {
        .el-table__cell {
          background-color: #051363 !important;
        }
      }
    }
    td {
      &.el-table__cell {
        border-bottom: 0;
      }
    }
    th.el-table__cell.is-leaf {
      border-bottom: 0;
      background-color: #051363;
    }
    &::before {
      height: 0;
    }
    .el-table__body-wrapper.is-scrolling-none {
      background-color: #051363;
    }
    .el-table__fixed-right::before, .el-table__fixed::before {
      background: none;
    }
    .hover-row {
      .el-table__cell {
        background: #051363;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.chart-wrap {
  .header {
    display: flex;
    .title {
      font-size: 18px;
      @media screen and (max-width: 1200px) {
        font-size: 0.32rem;
        padding-left: 0.24rem;
      }
    }
  }
}
.main-body {
  .el-statistic .con {
    color: #52eaed;
    justify-content: flex-start;
    .number {
      font-size: 24px;
      padding: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding-bottom: 1rem;
    .el-statistic .con {
      color: #52eaed;
      .number {
        font-size: 0.36rem;
      }
      // padding: 0.22rem 0;
    }
  }
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  
}
.el-loading-mask {
  background-color: rgb(61 61 139 / 80%) !important;
}
.amount {
  .el-loading-mask {
    background-color: transparent !important;
  }
}
.h5-loading-wrap {
  font-size:0.20rem;
  padding-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
